export const CREATE_TOKEN = "CREATE_TOKEN";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const GET_BY_DATE = "GET_BY_DATE";
export const GET_COMMENTS = "GET_COMMENTS";
export const GET_CURRENT_COMMENT = "GET_CURRENT_COMMENT";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_PLAN = "GET_CURRENT_PLAN";
export const GET_SLEEP_STAGE = "GET_SLEEP_STAGE";
export const GET_SLEEP_SESSION = "GET_SLEEP_SESSION";
export const GET_STEPS = "GET_STEPS";
export const GET_USERS_RESPONSE = "GET_USERS_RESPONSE";
export const MY_AUTH_APP = "MY_AUTH_APP_1";
export const PLAN_EXPIRATION_DATE = "PLAN_EXPIRATION_DATE";
export const POST_USER_WITH_GOOGLE = "POST_USER_WITH_GOOGLE";
export const IS_GOOGLE_USER = "IS_GOOGLE_USER";
export const IS_PASSWORD_SET_UP = "IS_PASSWORD_SET_UP";
export const USER_ID = "USER_ID";
export const IS_ADMIN = "IS_ADMIN";
